import GoalsPage from "../pages/Goals/GoalsPage";
import Home from "../pages/Home/Home";
import LoginPage from "../pages/LoginSignUp/LoginPage";

export const routes = [
  {
    path: "/",
    name: "Home",
    element:<Home/>,
  },
  {
    path: "/signup",
    name: "Signup",
    element: <LoginPage/>,
  },
  {
    path: "/select-goals",
    name: "Select Goals",
    element: <GoalsPage/>,
  },
];
