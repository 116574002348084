import React from "react";
import { Routes, Route } from "../utils/imports";
import { routes } from "../utils/routes";

const AppRoutes = () => {
  return (
    <div>
      <Routes>
        {routes.map((route) => (
          <Route key={route.path} path={route.path} element={route.element} />
        ))}
      </Routes>
    </div>
  );
};

export default AppRoutes;
