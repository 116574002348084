import { useNavigate } from "react-router-dom";
import styles from "./common_css/common.module.css";
import { Icon, useState } from "../../utils/imports";
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';

export const CardComponentHomePage = ({
  name,
  image,
  description,
  exploreText,
  link,
}) => {
  const navigation = useNavigate();
  return (
    <div className={styles.homepageCard1}>
      <div className={styles.homepageCard1Image}>
        <span className={styles.homepageCard1Name}>{name}</span>
        <span className={styles.homepageCard1imag}>
          <img
            className={styles.homepageCard1imageMain}
            src={image}
            alt="alttext"
          />
        </span>
      </div>
      <div className={styles.homepageCard1Description}>
        <span className={styles.homepageCard1description1}>{description}</span>
        <span className={styles.homepageCard1explore}>
          <span>{exploreText}</span>
          <span>
            <Icon
              icon="solar:arrow-right-linear"
              style={{ color: "#2A2BFA", marginLeft: "0.5rem" }}
            />
          </span>
        </span>
      </div>
    </div>
  );
};

export const CardComponentHomePage1 = ({ name, id, yOffsetP }) => {
  const [isActive, setIsActive] = useState(false);

  const handleCardClick = () => {
    const element = document.getElementById(id);
    if (element) {
      const yOffset = yOffsetP || 0;
      const yPosition =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({ top: yPosition, behavior: "smooth" });
      setIsActive(true); 
    }
  };

  return (
    <div
      className={`${styles.homepageCard21} ${isActive ? styles.active : ""}`} 
      onClick={handleCardClick}
      role="button"
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          handleCardClick();
          setIsActive(true); 
        }
      }}
      onBlur={() => setIsActive(false)} 
      onMouseLeave={() => setIsActive(false)} 
    >
      <span className={styles.homepageCard21Name}>{name}</span>
    </div>
  );
};

export const CardComponentHomePage2AI = ({ name, iconImage }) => {
  const navigation = useNavigate();
  return (
    <p className={styles.homepageCard2AI}>
      <p className={styles.homepageCard2AIIcon}>
        <img
          className={styles.homepageCard2AIIconImage}
          src={iconImage}
          alt={name}
        />
      </p>
      <p className={styles.homepageCard2AIName}>{name}</p>
    </p>
  );
};

export const CardComponentHomePage2Course = ({
  name,
  image,
  description,
  courseText,
}) => {
  const navigation = useNavigate();

  return (
    <p className={styles.homepageCard3Course}>
      <p className={styles.homepageCard3CourseIcon}>
        <img src={image} alt={name} />
      </p>
      <p className={styles.homepageCard3CourseName}>{name}</p>
      <p className={styles.homepageCard3CourseDiscription}>{description}</p>
      <p className={styles.homepageCard3CourseDiscription2}>
        <span>{courseText}</span>
        <span>
          <Icon
            icon="solar:arrow-right-linear"
            style={{
              color: "#2A2BFA",
              marginLeft: "0.5rem",
              width: "30px",
              height: "20px",
            }}
          />
        </span>
      </p>
    </p>
  );
};

export const CardComponentHomePage2Community = ({
  name,
  image,
  description,
}) => {
  const navigation = useNavigate();
  return (
    <p className={styles.homepageCard4Community}>
      <p className={styles.homepageCard4CommunityIcon}>
        <img
          className={styles.homepageCard4CommunityIconImage}
          src={image}
          alt={name}
        />
      </p>
      <p className={styles.homepageCard4CommunityName}>{name}</p>
      <p className={styles.homepageCard4CommunityDiscription}>{description}</p>
    </p>
  );
};

export const CardComponentHomePage2Capsule = ({ name, image }) => {
  const navigation = useNavigate();
  return (
    <p className={styles.homepageCard5Capsule}>
      {/* <span className={styles.homepageCard5CapsuleIcon}>
        <img src={image} alt={name} />
      </span> */}

      <span> 💊 {name}</span>
    </p>
  );
};


export const  SwiperComponentGoals = ({
  slides = [], // Default is an empty array
  spaceBetween = 50, // Default spacing between slides
  slidesPerView = 3, // Default number of slides visible at once
  onSlideChange = () => {}, // Default slide change handler
  onSwiper = () => {} // Default swiper handler
}) => {
  return (
    <Swiper
      spaceBetween={spaceBetween}
      slidesPerView={slidesPerView}
      onSlideChange={onSlideChange}
      onSwiper={onSwiper}
    >
      {slides.map((slide, index) => (
        <SwiperSlide key={index}>
          {slide}
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
