import React from "react";
import "./GoalsPage.css";
import { SwiperComponentGoals } from "../../components/common/Cards";
const GoalsPage = () => {
    const slides1=[{
        name:"Upcoming"
    },{
        name:"Bank"
    },{
        name:"Gate"
    }]
  return (
    <div className="GoalsPage">
      <div className="GoalsPage__container">
        <p className="GoalsPage__container__1">
          Select Your <span>Goals</span>
        </p>
        <div className="GoalsPage__container__2">
            <div>
                <SwiperComponentGoals/>
            </div>
        </div>
      </div>
    </div>
  );
};

export default GoalsPage;
