const images = {
  navBarLogo: require("../assets/images/NavBar/NavbarLogo.svg").default,
  navBarGiftIcon: require("../assets/images/NavBar/gifticon.png"),
  homePageBanner1: require("../assets/images/HomePage/homePageBanner1.svg")
    .default,
  homePageLearningIcon1: require("../assets/images/HomePage/learningIcons/calander.png"),
  homePageLearningIcon2: require("../assets/images/HomePage/learningIcons/teacher.png"),
  homePageLearningIcon3: require("../assets/images/HomePage/learningIcons/pen.png"),
  homePageLearningIcon4: require("../assets/images/HomePage/learningIcons/hand.png"),
  homePageLearningIcon5: require("../assets/images/HomePage/learningIcons/notebook.png"),
  homePageLearningIcon6: require("../assets/images/HomePage/learningIcons/hands.png"),
  homePageLearningIcon7: require("../assets/images/HomePage/learningIcons/arrow.png"),
  homePageLearningIcon8: require("../assets/images/HomePage/learningIcons/rocket.png"),
  homePageAIIcons1: require("../assets/images/HomePage/AiIcons/Frame.png"),
  homePageAIIcons2: require("../assets/images/HomePage/AiIcons/Frame-1.png"),
  homePageAIIcons3: require("../assets/images/HomePage/AiIcons/Frame-2.png"),
  homePageAIImage: require("../assets/images/HomePage/AiIcons/AiImage.png"),
  homePageCourseIcon1: require("../assets/images/HomePage/CoursesIcon/1.png"),
  homePageCourseIcon2: require("../assets/images/HomePage/CoursesIcon/2.png"),
  homePageCourseIcon3: require("../assets/images/HomePage/CoursesIcon/3.png"),
  homePageHandWrittenNotes: require("../assets/images/HomePage/HandWrittenNotes.png"),
  testSeriesImage: require("../assets/images/HomePage/TestSeriesImage.png"),
  pyqImage: require("../assets/images/HomePage/PYQImage.png"),
  homePageComunityImage1: require("../assets/images/HomePage/Community/Frame.png"),
  homePageComunityImage2: require("../assets/images/HomePage/Community/Frame-1.png"),
  homePageComunityImage3: require("../assets/images/HomePage/Community/Frame-2.png"),
  homePageComunityImage4: require("../assets/images/HomePage/Community/Vector.png"),
  hompageDownloadAppImage: require("../assets/images/HomePage/iphonePic.png"),
loginPageBackgroundImage: require("../assets/images/Login/loginBackground.svg").default,
};

export default images;
