import React from "react";
import "./Navbar/Navbar.css";
import images from "../../utils/images";
import { Button, Form } from "react-bootstrap";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useNavigate } from "react-router-dom";

const Navbar = ({isCSS}) => {
  const navigate = useNavigate();
  return (
    <div className={isCSS ? "navbar" : ""}>
      <div className="navbar_container fixMaxWidth gridLayoutNavbar">
        <div className="navbar_logo_container">
          <span className="navbar_logo_container_1">
            <Icon
              icon="ic:baseline-menu"
              className="navbar_logo_container_1_1"
              onClick={() => {}}
            />
          </span>
          <span className="navbar_logo_container_2">
            <span className="navbar_logo_container_2_1">
              <img src={images?.navBarLogo} alt="logo" />
            </span>
            <span className="navbar_logo_container_2_2">Digital Benchers</span>
          </span>
        </div>
        <div className="navbar_search_container">
          <div className="navbar_search_container_1">
            <div>
              <Form.Control
                type="search"
                placeholder="Search courses, test series and exams"
                className="navbar_search_container_1_1"
                aria-label="Search courses, test series and exams"
              />
            </div>
            <div>
              <Icon
                icon="ic:round-search"
                className="navbar_search_container_1_2"
              />
            </div>
          </div>
        </div>
        <div className="navbar_user_container">
          <span className="navbar_user_container_1">
            <img src={images?.navBarGiftIcon} alt="icon" />
          </span>
          <span className="navbar_user_container_2">
            <Button onClick={() => navigate("/signup")}>Login</Button>
          </span>
          <span className="navbar_user_container_3">
            <Button variant="primary">Get the App</Button>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
