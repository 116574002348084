import React from "react";
import "./Home.css";
import { BannerComponent } from "../../components/common/Banners";
import images from "../../utils/images";
import {
  CardComponentHomePage,
  CardComponentHomePage1,
  CardComponentHomePage2AI,
  CardComponentHomePage2Capsule,
  CardComponentHomePage2Community,
  CardComponentHomePage2Course,
} from "../../components/common/Cards";
import { Button } from "react-bootstrap";
import Navbar from "../../components/layout/Navbar";
const Home = () => {
  const cards = [
    {
      name: "Study Planner",
      image: images.homePageLearningIcon1,
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      exploreText: "Explore Study Planner",
    },
    {
      name: "Course",
      image: images.homePageLearningIcon2,
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
      exploreText: "Explore Course",
    },
    {
      name: "Test series",
      image: images.homePageLearningIcon3,
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
      exploreText: "Explore Test series",
    },
    {
      name: "Hand written notes",
      image: images.homePageLearningIcon4,
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
      exploreText: "Explore Hand written notes",
    },
    {
      name: "PYQ’s",
      image: images.homePageLearningIcon5,
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
      exploreText: "Explore PYQ’s",
    },
    {
      name: "Community",
      image: images.homePageLearningIcon6,
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
      exploreText: "Explore Community",
    },
    {
      name: "Capsule course",
      image: images.homePageLearningIcon7,
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
      exploreText: "Explore Capsule course",
    },
    {
      name: "Skills",
      image: images.homePageLearningIcon8,
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
      exploreText: "Explore Skills",
    },
  ];
  const cards1 = [
    { name: "Study Planner", id: "1", yOffset: 212 },
    {
      name: "Courses",
      id: "2",
      yOffset: -180,
    },
    {
      name: "Tests with video solution",
      id: "3",
      yOffset: -230,
    },
    {
      name: "Handwritten Notes by Toppers",
      id: "4",
      yOffset: -280,
    },
    {
      name: "PYQs with videos",
      id: "5",
      yOffset: -282,
    },
    {
      name: "Capsule Course",
      id: "6",
      yOffset: 460,
    },
  ];

  const cards2 = [
    {
      name: "Set Your exam goals",
      iconImage: images.homePageAIIcons1,
    },
    {
      name: "Input Your Availability",
      iconImage: images.homePageAIIcons2,
    },
    {
      name: "Set Your exam goals",
      iconImage: images.homePageAIIcons3,
    },
  ];

  const cards3 = [
    {
      name: "Foundation Course",
      iconImage: images.homePageCourseIcon1,
      description:
        " (suitable for learners having at least 6-8 months of prep time in Govt Exams segment and 1 year of prep time in PUG and K12 exams)",
      courseText: "Start Foundation Course",
    },
    {
      name: "Crash Course",
      iconImage: images.homePageCourseIcon2,
      description:
        "(suitable for learners having 3-4 months of prep time in Govt. Exams segment and 6-8 month of prep time in PUG & K12 exams)",
      courseText: "Start Crash Course",
    },
    {
      name: "Express Course",
      iconImage: images.homePageCourseIcon3,
      description:
        "(suitable for learners who want to brush up their concepts in 1-2 months of prep time in any segment. This course is ideal for those learners who already have a deep understanding of the concepts (thorough understanding)",
      courseText: "Start Express Course",
    },
  ];

  const cards4 = [
    {
      name: "Discussion Forums",
      imageIcon: images.homePageComunityImage1,
      description:
        "Engage in topic-based discussions, post questions, and participate in debates on key subjects.",
    },
    {
      name: "Study Groups",
      imageIcon: images.homePageComunityImage2,
      description:
        "Join study groups based on your exam or subject area. Work together, share strategies, and track each other’s progress.",
    },
    {
      name: "Doubts & Solutions",
      imageIcon: images.homePageComunityImage3,
      description:
        "Post your doubts, and receive solutions from other learners and mentors. Respond to queries and help others along the way.",
    },
    {
      name: "Challenges & Leaderboards",
      imageIcon: images.homePageComunityImage4,
      description:
        "Participate in weekly or monthly challenges. Earn points by solving quizzes, contributing to discussions, and climb the leaderboard.",
    },
  ];

  const cards5 = [
    { name: "💊 Bite-Sized Learning", imageIcon: images.homePageHealthIcon1 },
    { name: "💊 Expert Instructors", imageIcon: images.homePageHealthIcon2 },
    { name: "💊 Last-Minute Prep", imageIcon: images.homePageHealthIcon3 },
    {
      name: "💊 Video Lectures & Practice Questions",
      imageIcon: images.homePageHealthIcon4,
    },
  ];
  return (
    <div className="HomePage_Container">
      <div className="navbar_container_main">
        <Navbar isCSS={true} />
        <Navbar isCSS={false} />
      </div>
      <div className="HomePage_Body">
        <div className="homepage_sub_container">
          <div className="homepage_menubar_container">
            <div className="gridContainer1 fixMaxWidth">
              {cards1 &&
                cards1?.map((card, index) => (
                  <CardComponentHomePage1
                    key={index}
                    name={card.name}
                    id={card.id}
                    yOffsetP={card.yOffset}
                  />
                ))}
            </div>
          </div>
          <div id="1" className="homepage_sub_sub_container1 fixMaxWidth">
            <BannerComponent
              css="fixMaxWidth"
              imagecss="fixMaxWidthBanner"
              image={images.homePageBanner1}
            />
          </div>
          <div className="homepage_sub_sub_container2 fixMaxWidth">
            <p>
              Join India’s <span>#1</span> Learning platform with{" "}
              <span>customized Courses</span> to fit your schedule
            </p>
            <p className="homepage_sub_sub_container2_p2">
              Over 10 crore learners trust us for <span>online Lectures</span>
            </p>
            <div className="homepage_sub_sub_container2_p3 fixMaxWidth gridLayout3 ">
              {cards &&
                cards?.map((card, index) => (
                  <CardComponentHomePage
                    key={index}
                    name={card.name}
                    image={card.image}
                    description={card.description}
                    exploreText={card.exploreText}
                    link="#"
                  />
                ))}
              <div className="homepage_sub_sub_container2_p3_p3_p">
                <Button className="homepage_sub_sub_container2_p3_p3_p_button">
                  Start Your Preparation
                </Button>
              </div>
            </div>
          </div>
          <div className="homepage_sub_sub_container3">
            <div className="fixMaxWidth gridLayoutCustomHomeAI">
              <div className="homepage_sub_sub_container3_p1">
                <p>
                  Take Control of Your Preparation with the AI-Powered
                  <span className="homepage_sub_sub_container3_p1_span">
                    Study Planner 🗓️
                  </span>
                </p>
                <p className="homepage_sub_sub_container3_p1_p1">
                  Create Personalized Study Plans in Minutes and Stay on Track
                  to Achieve Your Goals
                </p>
                <p className="homepage_sub_sub_container3_p1_p2">
                  <p>How the AI-Powered Study Planner Works</p>
                  <p className="gridLayout3">
                    {cards2 &&
                      cards2.map((card, index) => (
                        <CardComponentHomePage2AI
                          key={index}
                          name={card.name}
                          iconImage={card.iconImage}
                        />
                      ))}
                  </p>
                </p>
              </div>
              <div className="homepage_sub_sub_container3_p2">
                <span className="homepage_sub_sub_container3_p2_span1">
                  <img
                    className="homepage_sub_sub_container3_p2_span1_img"
                    src={images.homePageAIImage}
                    alt="image"
                  />
                </span>
                <span className="homepage_sub_sub_container3_p2_span2Btn">
                  <Button className="homepage_sub_sub_container3_p2_span2">
                    Get Started
                  </Button>
                </span>
              </div>
            </div>
          </div>
          <div id="2" className="homepage_sub_sub_container4 ">
            <div className="homepage_sub_sub_container4_p1 fixMaxWidth">
              <p>
                Find the Perfect{" "}
                <span className="homepage_sub_sub_container4_p1_span_text_color">
                  Course
                </span>{" "}
                for Your Exam Prep
              </p>
              <p>
                Choose from Our Curated Collection of Courses – Tailored to Fit
                Your Learning Needs
              </p>
              <p className="gridLayout3 fixMaxWidth">
                {cards3 &&
                  cards3.map((card, index) => (
                    <CardComponentHomePage2Course
                      key={index}
                      name={card.name}
                      image={card.iconImage}
                      description={card.description}
                      courseText={card.courseText}
                    />
                  ))}
              </p>
            </div>
          </div>
          <div id="3" className="homepage_sub_sub_container41">
            <div className="homepage_sub_sub_container41_p1">
              <div className="gridLayout2 fixMaxWidth">
                <div className="homepage_sub_sub_container41_p1_p1">
                  <img
                    className="homepage_sub_sub_container41_p1_p1_img"
                    src={images.testSeriesImage}
                    alt="BannerImage"
                  />
                </div>
                <div className="homepage_sub_sub_container41_p1_p2">
                  <p className="homepage_sub_sub_container41_p1_p2_p1">
                    <span className="homepage_sub_sub_container41_p1_p2_p1_span">
                      Master Your Exam Prep with Comprehensive
                      <span className="homepage_sub_sub_container41_p1_p2_p1_span11">
                        {" "}
                        Test Series
                      </span>{" "}
                      📝
                    </span>
                    <span className="homepage_sub_sub_container41_p1_p2_p1_span1">
                      Boost Your Confidence and Sharpen Your Skills with Our
                      Expert-Curated Mock Tests
                    </span>
                  </p>

                  <p className="flexLayout homepage_sub_sub_container5_p2_p2btn p1p1p1p1">
                    <Button className="homepage_sub_sub_container5_p2_span2 borderStartTrial">
                      Start Your Free Trial
                    </Button>
                    <Button className="homepage_sub_sub_container5_p2_span2">
                      Explore All Test Series
                    </Button>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div id="4" className="homepage_sub_sub_container5 ">
            <div className="gridLayout2 fixMaxWidth">
              <div></div>
              <div className="homepage_sub_sub_container5_p2">
                <p className="homepage_sub_sub_container5_p2_p1">
                  <span>Unlock Exclusive Handwritten Notes by Toppers 👨🏻‍🎓</span>
                  <span>
                    Access Topic-wise Notes to Master Key Concepts and Boost
                    Your Exam Prep
                  </span>
                </p>
                <p className="homepage_sub_sub_container5_p2_p2btn flexLayout">
                  <Button className="homepage_sub_sub_container5_p2_span2">
                    Get Instant Access to Handwritten Notes
                  </Button>
                  <Button className="homepage_sub_sub_container5_p2_span2">
                    Browse Notes by Subject
                  </Button>
                </p>
              </div>
            </div>
          </div>
          <div id="5" className="homepage_sub_sub_container41">
            <div className="homepage_sub_sub_container41_p1">
              <div className="gridLayout2 fixMaxWidth">
                <div className="homepage_sub_sub_container41_p1_p2">
                  <p className="homepage_sub_sub_container41_p1_p2_p1">
                    <span className="homepage_sub_sub_container41_p1_p2_p1_span">
                      Ace Your Exams with Real
                      <span className="homepage_sub_sub_container41_p1_p2_p1_span11">
                        {" "}
                        PYQs
                      </span>{" "}
                      and Detailed Video Solutions 📝
                    </span>
                    <span className="homepage_sub_sub_container41_p1_p2_p1_span1">
                      Practice with Actual Previous Year Questions and Boost
                      Your Exam Readiness
                    </span>
                  </p>

                  <p className="flexLayout homepage_sub_sub_container5_p2_p2btn p1p1p1p1">
                    <Button className="homepage_sub_sub_container5_p2_span2 borderStartTrial">
                      Start Practicing with PYQs
                    </Button>
                    <Button className="homepage_sub_sub_container5_p2_span2">
                      Browse by Exam
                    </Button>
                  </p>
                </div>
                <div className="homepage_sub_sub_container41_p1_p1">
                  <img
                    className="homepage_sub_sub_container41_p1_p1_img"
                    src={images.pyqImage}
                    alt="BannerImage"
                  />
                </div>
              </div>
            </div>
          </div>
          <div id="6" className="homepage_sub_sub_container4 ">
            <div className="homepage_sub_sub_container4_p1 fixMaxWidth">
              <p>
                Join the Learning{" "}
                <span className="homepage_sub_sub_container4_p1_span_text_color">
                  Community
                </span>{" "}
                and Grow Together 🤝🏼
              </p>
              <p>
                Connect, Share, and Learn with Fellow Students and Experts in
                Our Engaging Community
              </p>
              <p className="gridLayout4 fixMaxWidth">
                {cards4 &&
                  cards4.map((card, index) => (
                    <CardComponentHomePage2Community
                      key={index}
                      name={card.name}
                      image={card.imageIcon}
                      description={card.description}
                    />
                  ))}
              </p>
              <p className="homepage_sub_sub_container4_p1_p2p3 flexLayout">
                <Button className="homepage_sub_sub_container4_p1_span21">
                  Join the Community
                </Button>
                <Button className="homepage_sub_sub_container4_p1_span22">
                  Explore Discussions
                </Button>
              </p>
            </div>
          </div>
          <div className="homepage_sub_sub_container6">
            <div>
              <p>Fast-Track Your Exam Prep with Expert-Led </p>
              <p>
                <span>Capsule Courses</span>
              </p>
              <p>
                Quick, Focused Learning from the Best Educators – Perfect for
                Last-Minute Revision
              </p>
              <p>
                <span className="fixMaxWidth gridLayout4">
                  {cards4 &&
                    cards4.map((card, index) => (
                      <CardComponentHomePage2Capsule
                        key={index}
                        name={card.name}
                        image={card.imageIcon}
                        description={card.description}
                      />
                    ))}
                </span>
              </p>
              <p className="homepage_sub_sub_container4_p1_p2p3 flexLayout centerItems">
                <Button className="homepage_sub_sub_container4_p1_span21">
                  Start a Capsule Course
                </Button>
                <Button className="homepage_sub_sub_container4_p1_span22">
                  Explore All Capsules
                </Button>
              </p>
            </div>
          </div>
          <div className="homepage_sub_sub_container41">
            <div className="homepage_sub_sub_container41_p1">
              <div className="homepage_sub_sub_container41_p1_p1_p2 gridLayout2 fixMaxWidth">
                <div className="homepage_sub_sub_container41_p1_p2">
                  <p className="homepage_sub_sub_container41_p1_p2_p1">
                    <span className="homepage_sub_sub_container41_p1_p2_p1_span">
                      Ace Your Exams with Real
                      <span className="homepage_sub_sub_container41_p1_p2_p1_span11">
                        {" "}
                        PYQs
                      </span>{" "}
                      and Detailed Video Solutions 📝
                    </span>
                    <span className="homepage_sub_sub_container41_p1_p2_p1_span1">
                      Practice with Actual Previous Year Questions and Boost
                      Your Exam Readiness
                    </span>
                  </p>

                  <p className="flexLayout homepage_sub_sub_container5_p2_p2btn p1p1p1p1">
                    <Button className="homepage_sub_sub_container5_p2_span2 borderStartTrial">
                      Start Practicing with PYQs
                    </Button>
                    <Button className="homepage_sub_sub_container5_p2_span2">
                      Browse by Exam
                    </Button>
                  </p>
                </div>
                <div className="homepage_sub_sub_container41_p1_p1">
                  <img
                    className="homepage_sub_sub_container41_p1_p1_img1"
                    src={images.hompageDownloadAppImage}
                    alt="BannerImage"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>Footer</div>
    </div>
  );
};

export default Home;
