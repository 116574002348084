import React, { useState } from "react";
import "./LoginPage.css";
import { useForm } from "react-hook-form";
import { Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
const LoginPage = () => {
  const [isLogin, setIsLogin] = useState(true);
  const navigate=useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => console.log(data);
  return (
    <div className="login_page__container">
      <div className="login_page__container1 fixMaxWidth">
        <p className="login_page__container1__1">
          <span>Welcome Back! </span>Log In to Continue Learning or Sign Up to{" "}
          <span>Get Started</span>
        </p>
        <p className="login_page__container1__2">
          Access Your Personalized Dashboard, Courses, and More
        </p>
        <div className="login_page__container2">
          {isLogin ? (
            <>
              {" "}
              <div className="login_page__container2__1">
                <span>Create Your Free Account and Start Learning</span>
                <span>
                  <Form
                    onSubmit={handleSubmit(onSubmit)}
                    className="login_page__container2__1__1"
                  >
                    <Form.Group className="login_page__container2__1__1__1_2">
                      <Form.Control
                        className={`login_page__container2__1__1__1 ${
                          errors.email ? "error" : ""
                        }`}
                        type="text"
                        placeholder="Name"
                        {...register("name", { required: true })}
                      />
                      <Form.Control
                        className={`login_page__container2__1__1__1 ${
                          errors.email ? "error" : ""
                        }`}
                        type="number"
                        placeholder="Phone Number"
                        {...register("email", { required: true })}
                      />
                      <Form.Control
                        className={`login_page__container2__1__1__1 ${
                          errors.email ? "error" : ""
                        }`}
                        type="email"
                        placeholder="Email"
                        {...register("email", { required: true })}
                      />
                      <Form.Select
                        className={`login_page__container2__1__1__1 ${
                          errors.email ? "error" : ""
                        }`}
                        aria-label="Default select example"
                        {...register("email", { required: true })}
                      >
                        <option>Where Did You Here About Us</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </Form.Select>
                      <Form.Control
                        className={`login_page__container2__1__1__1 ${
                          errors.password ? "error" : ""
                        }`}
                        type="password"
                        placeholder="Create Password"
                        {...register("password", { required: true })}
                      />
                    </Form.Group>
                    <Form.Group className="login_page__container2__1__1__2r">
                      <Button variant="primary" type="submit">
                        Create Account
                      </Button>
                    </Form.Group>
                  </Form>
                </span>
              </div>
              <div className="login_page__container2__2">
                <div className="login_page__container2__2__1">
                  <p>Already have an account?</p>
                  <Button
                    onClick={() => navigate("/select-goals")}
                    className="login_page__container2__2__1__1"
                    type="submit"
                  >
                    Log In 
                  </Button>
                </div>
              </div>
            </>
          ) : (
            <>
              <>
                {" "}
                <div className="login_page__container2__2">
                  <div className="login_page__container2__2__1">
                    <p>  Don't have an account?</p>
                    <Button
                      onClick={() => setIsLogin(!isLogin)}
                      className="login_page__container2__2__1__1"
                      type="submit"
                    >
                    Create Account
                    </Button>
                  </div>
                </div>
                <div className="login_page__container2__1">
                  <span>Login To Your Account</span>
                  <span>
                    <Form
                      onSubmit={handleSubmit(onSubmit)}
                      className="login_page__container2__1__1"
                    >
                      <Form.Group className="login_page__container2__1__1__1_2r">
                        
                        <Form.Control
                          className={`login_page__container2__1__1__1 ${
                            errors.phone ? "error" : ""
                          }`}
                          type="number"
                          placeholder="Phone Number"
                          {...register("phone", { required: true })}
                        />
                        <Form.Control
                          className={`login_page__container2__1__1__1 ${
                            errors.password ? "error" : ""
                          }`}
                          type="password"
                          placeholder="Password"
                          {...register("password", { required: true })}
                        />
                      </Form.Group>
                      <Form.Group className="login_page__container2__1__1__2r">
                        <Button variant="primary" type="submit">
                          Login
                        </Button>
                      </Form.Group>
                    </Form>
                  </span>
                </div>
                
              </>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
